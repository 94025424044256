import { observer } from "mobx-react";
import { useStores } from "../../hooks";
import { Input } from "../elements";

interface props {
  onEnter: () => Promise<void>,
}

const LoginForm = observer(({ onEnter }: props) => {
  const { authStore } = useStores();
  const { account } = authStore;

  const onKeyDown = (ev: React.KeyboardEvent<HTMLFormElement>) => {
    console.log(ev.key)
    if (ev.key === 'Enter') {
      onEnter()
    }
  }

  return (
    <form onKeyDown={onKeyDown}>
      <div>
        <label>Gebruikersnaam</label>
        <Input 
          entity={account} 
          name="email" 
          placeholder="Vul hier je email in"
        />
      </div>

      <div>
        <label>Wachtwoord</label>
        <Input 
          type="password" 
          entity={account} 
          name="password" 
          placeholder="Vul hier je wachtwoord in"
        />
      </div>
    </form>
  )
})

export default LoginForm;
