import { LoginForm } from '../forms';
import { useRedirect, useStores } from '../../hooks';
import { SpinnerRound } from 'spinners-react';
import { observer } from 'mobx-react';
import { locations } from '../../constants';
import styles from '../../assets/scss/login.module.scss'
import logo from '../../assets/images/engelendael.svg'
import { useState } from 'react';

const Login = observer(() => {
  const { authStore } = useStores();
  const { redirect } = useRedirect();
  const [error, setError] = useState<undefined|string>(undefined);
  const [ loading, setLoading ] = useState(false)

  const login = async () => {
    setLoading(true)
    const { status } = await authStore.login();
    setLoading(false)
    switch(status) {
      case 200:
        setError(undefined)
        redirect(locations.SELECT_APP);
      break;
      case 401:
        setError('De gebruikersnaam en of wachtwoord zijn niet correct.')
      break;
      default:
        setError('Er ging iets mis. Probeer later opnieuw...')
    }
  };

  return (
    <>
     <header className={styles.header}>
        <img src={logo} alt="engelendael logo"/>
      </header>
 
      <main>
        

        {
          error !== undefined && (
            <div className="error">
            { error }
            </div>
          )
        }

        <section className={styles.loginContainer}>
          {
            !loading && (
              <>
                <LoginForm onEnter={login} />
                <div className="btn" onClick={login}>Login</div>
              </>
            )
          }

          {
            loading && (
              <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                <SpinnerRound size="100" color="#E51F1F"/>
                <strong style={{marginTop: '20px', fontWeight: 'bold'}}>De gegevens worden gevalideerd.</strong>
              </div>
            )
          }
        </section>
      </main>  
    </>
    
  );
});

export default Login;


