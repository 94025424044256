import { makeObservable, observable } from "mobx";
import Model from "./Model";

class Account extends Model {
  email: string
  password: string

  constructor() {
    super();
    this.email = ''
    this.password = '';

    makeObservable(this, {
      email: observable,
      password: observable,
    })
  }
}

export default Account;
