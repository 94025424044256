import { makeObservable, observable } from "mobx";
import Store from "./store";

class GlobalStore extends Store {
  test: string;

  constructor() {
    super()
    this.test = "testing"

    makeObservable(this, {
      test: observable
    })
  }
}

export default new GlobalStore();
