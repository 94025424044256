import { observer } from "mobx-react";
import { useRedirect, useStores } from '../../hooks';
import styles from '../../assets/scss/selectApp.module.scss';
import { locations } from '../../constants';

const SelectApp = observer(() => {
  const { authStore } = useStores();
  const { redirect } = useRedirect();

  const openCherries = () => {
    window.location.href = window.location.origin.replace("sso", "cherries")
  };

  const openGateSettings = () => {
    window.location.href = window.location.origin.replace("sso", "gatesettings")
  };

  const logout = async () => {
    const { status } = await authStore.logout()
    switch(status) {
      case 200:
        redirect(locations.LOGIN);
      break;
      default:
        console.error('Er ging iets mis. Probeer later opnieuw...')
    }
  };

  return (
    <>
      <header>
        <div className={styles.logout} onClick={logout}>
          <i className="fas fa-sign-out-alt" />
          Uitloggen
        </div>

        <h2 className="title" style={{marginTop: '100px'}}>
          Kies de applicatie die je wenst te openen
        </h2>
      </header>
      <main>
        <div className={styles.options}>
          <div className={styles.option} onClick={openCherries}>
            <i className="fas fa-inbox" />
            Kersen
          </div>

          <div className={styles.option} onClick={openGateSettings}>
            <i className="fas fa-cog" />
            Poort instellingen
          </div>
        </div>
      </main>
    </>
  )
})

export default SelectApp;
