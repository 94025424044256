import { useNavigate } from 'react-router-dom';
import * as stores from './stores'

export const useStores = () => {
  return stores
}

export const useRedirect = () => {
  const navigate = useNavigate();

  const redirect = (location: string) => {
    navigate(location)
  };

  return {
    redirect
  }
};

