import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { locations } from "../constants";
import { getCookieValue } from "../utils";
import { Login, SelectApp } from './pages'
import { Footer } from './partials'

interface props {
  children: JSX.Element
}

const ProtectedRoute = ({ children }: props) => {
  try {
    const { expires } = getCookieValue() as { expires: number, authenticated: boolean}
    if (expires < (Date.now() / 1000)) {
      return <Navigate to={locations.LOGIN} />
    }
  } catch (err) {
    return <Navigate to={locations.LOGIN} />
  }

  return children;
};

const Router = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path={locations.LOGIN} element={<Login />}/>
          <Route path={locations.SELECT_APP} element={
            <ProtectedRoute>
              <SelectApp />
            </ProtectedRoute>
          }/>
        </Routes>
      </BrowserRouter>
      <Footer />
    </>
  )
}

export default Router;

